export const useScrollToAnchor = async(href: string) => {
  if (import.meta.client) {
    const router = useRouter();

    const [path, anchorId] = href.split('#');
    const headerDesktop = document.querySelector('.j-header');
    const headerMobile = document.querySelector('.j-header-mobile');
    const header = (headerDesktop || headerMobile) as HTMLElement;

    const headerHeight = header
    ? header.offsetHeight
    : 0;

    const scrollTo = () => {
      const anchor = document.querySelector(`.j-${anchorId}`);

      if (anchor) {
        const offsetTop = anchor.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
          top: offsetTop - headerHeight,
          behavior: 'smooth',
        });
      }
    };

    if (path === router.currentRoute.value.path) {
      scrollTo();
    } else if (anchorId) {
      await router.push({
        path,
        hash: `#${anchorId}`,
      });
      scrollTo();
    }
  }
};